<template>

    <!--@on-row-click="(params) => $emit('row_clicked', params)"-->

    <vue-good-table
        ref="table"
        class="mt-1"
        :columns="columns"
        :rows="data"
        :pagination-options="{
            enabled: true,
            perPage: pageLength
        }"
    >
        <template slot="table-row" slot-scope="props">

            <slot v-bind="props">
                <span>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </slot>

        </template>

        <template
            slot="pagination-bottom"
            slot-scope="props"
        >

            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} of {{ props.total }}
                    </span>

                    <b-dropdown
                        variant="none"
                        no-caret>

                        <template v-slot:button-content>
                            <span>({{pageLength}} per page) </span>
                            <feather-icon
                                icon="ChevronDownIcon"
                                size="16"
                                class="text-body align-middle mr-25"
                            />
                        </template>

                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                            {{ length }}
                        </b-dropdown-item>

                    </b-dropdown>
                </div>
                <div>
                    <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>
            </div>

        </template>

    </vue-good-table>

</template>
<script>

    import { VueGoodTable } from 'vue-good-table'
    import { BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue'

    export default {
        components: {
            BPagination,
            BDropdown,
            VueGoodTable,
            BDropdownItem
        },
        props: {
            data: {
                type: Array,
                required: true
            },
            columns: {
                type: Array,
                required: true
            },
            lengths: {
                type: Array,
                required: false,
                default: () => {
                    return [
                        '10',
                        '20',
                        '50',
                        '100'
                    ]
                }
            }
        },
        data() {
            return {
                pageLength: 10
            }
        },
        methods: {
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            }
        },
        created() {
            this.pageLength = this.lengths[0]
        },
        mounted() {
            this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
